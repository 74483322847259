<template>
  <div class="class-diary">
    <edudash-select
      v-model="selectedSchool"
      class="select"
      :items="getTeacherSchools"
      :placeholder="$t('views.class_diary.school_select_placeholder')"
    />
    <edudash-select
      v-model="selectedSchoolClass"
      :items="schoolClasses"
      :placeholder="$t('views.class_diary.school_class_select_placeholder')"
      text="name"
      class="select"
      :disabled="disableSchoolClass"
    />
    <edudash-date-picker
      v-model="date"
      :events="classDiaryDays"
      class="date-picker"
      :disabled="disableDate"
      :min-date="minDate"
    />
    <edudash-textarea
      v-model="description"
      :rules="[sizeRule]"
      :disabled="disabled"
    />
    <v-row class="btn-row">
      <v-col cols="6">
        <edudash-button
          :full-width="true"
          :disabled="!filledData"
          @click="send"
        />
      </v-col>
    </v-row>
    <div>
      <v-row
        justify="space-around"
        class="bottom-items"
      >
        <v-col
          class="item-container"
        >
          <edudash-icon-button
            :text="$t('views.class_diary.download_diaries')"
            icon="vertical_align_bottom"
            @click="downloadPDF"
          />
        </v-col>
        <v-col
          class="item-container"
        >
          <edudash-icon-button
            :text="$t('views.class_diary.share_diaries')"
            icon="share"
            @click="sharePDF"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import EdudashSelect from '@/components/forms/EdudashSelect.vue';
import EdudashDatePicker from '@/components/forms/EdudashDatePicker.vue';
import EdudashTextarea from '@/components/forms/EdudashTextarea.vue';
import EdudashButton from '@/components/forms/EdudashButton.vue';
import EdudashIconButton from '@/components/forms/EdudashIconButton.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ClassDiary',
  components: {
    EdudashSelect,
    EdudashDatePicker,
    EdudashTextarea,
    EdudashButton,
    EdudashIconButton,
  },
  data() {
    return {
      date: new Date().toISOString().slice(0, 10),
      selectedSchool: null,
      selectedSchoolClass: null,
      schoolClasses: [],
      description: '',
      minDate: new Date(new Date().getFullYear(), 0, 1).toISOString().slice(0, 10),
    };
  },
  computed: {
    ...mapGetters([
      'getTeacherSchools',
      'getClassDiary',
      'getClassDiaryDays',
      'getSchoolYearIsValid',
    ]),
    schoolItems() {
      return this.getTeacherSchools;
    },
    disabled() {
      return (!this.selectedSchoolClass
        || !this.isEditable
        || (this.selectedSchoolClass && !this.selectedSchoolClass.id))
        || !this.getSchoolYearIsValid;
    },
    isEditable() {
      return !(!this.selectedSchoolClass.active
        && !this.getClassDiary);
    },
    filledData() {
      return (
        this.selectedSchoolClass
        && this.selectedSchoolClass.id
        && this.description.length >= 5
        && this.getSchoolYearIsValid
      );
    },
    classDiaryDays() {
      return this.getClassDiaryDays;
    },
    disableSchoolClass() {
      return this.schoolClasses.length === 0;
    },
    disableDate() {
      return !this.selectedSchoolClass || !this.getSchoolYearIsValid;
    },
  },
  watch: {
    selectedSchool(value) {
      this.schoolClasses = value.school_classes;
      this.schoolClasses.unshift({ name: 'Todas as turmas', id: null });
      this.selectedSchoolClass = null;
      this.obtainClassDiary();
    },
    selectedSchoolClass() {
      if (this.selectedSchoolClass.id) {
        this.obtainClassDiary();
        this.findClassDiaryDays({ school_class_id: this.selectedSchoolClass.id });
      }
    },
    date() {
      this.obtainClassDiary();
    },
    getClassDiary(value) {
      this.description = value ? value.description : '';
    },
  },
  mounted() {
    this.findTeacherSchools();
    this.changeTab(this.$route.name);
  },
  methods: {
    ...mapActions([
      'findTeacherSchools',
      'sendClassDiary',
      'findClassDiary',
      'findClassDiaryDays',
      'findClassDiariesPDF',
      'changeToolbarText',
      'addErrorMessage',
      'changeTab',
    ]),
    send() {
      this.sendClassDiary({
        description: this.description,
        school_class_id: this.selectedSchoolClass.id,
        day: this.date,
      });
    },
    obtainClassDiary() {
      if (this.selectedSchoolClass) {
        this.findClassDiary({
          school_class_id: this.selectedSchoolClass.id,
          day: this.date,
        });
      }
    },
    sizeRule(text) {
      return text.length >= 5 || this.$t('views.class_diary.size_rule');
    },
    downloadPDF() {
      this.exportPdf(true, this.$t('views.class_diary.export.title', {
        kind: this.$t('views.class_diary.export.download'),
      }));
    },
    sharePDF() {
      this.exportPdf(false, this.$t('views.class_diary.export.title', {
        kind: this.$t('views.class_diary.export.share'),
      }));
    },
    exportPdf(download, toolbarText) {
      if (this.selectedSchoolClass) {
        this.changeToolbarText(toolbarText);
        this.$router.push({
          name: 'classDiaryExport',
          params: {
            download,
            schoolClass: this.selectedSchoolClass,
            school: this.selectedSchool,
          },
        });
        return;
      }
      this.addErrorMessage(this.$t('views.class_diary.school_class_required'));
    },
  },
};
</script>

<style lang="scss">
  .class-diary {
    @include font-monteserrat-bold;
    color: $color-primary;
    padding: 16px;
    .select {
      height: 24px;
      margin-bottom: 8px;
    }
    .btn-row {
      margin-bottom: 60px;
    }
    .bottom-items {
      position: absolute;
      width: 100%;
      bottom: 20px;
    }
  }
</style>
