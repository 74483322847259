<template>
  <v-textarea
    v-model="text"
    label="Escrever diário"
    class="edudash-textarea"
    :disabled="disabled"
    :change="emitChange()"
    :rules="rules"
  />
</template>
<script>

export default {
  name: 'EdudashTextarea',
  model: {
    prop: 'content',
    event: 'change',
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: this.content,
    };
  },
  watch: {
    content(value) {
      this.text = value;
    },
  },
  methods: {
    emitChange() {
      this.$emit('change', this.text);
    },
  },
};
</script>
<style lang="scss" scoped>
  .edudash-textarea.v-input ::v-deep {
    @include font-monteserrat-semi-bold;
    color: $text-color-secondary;
    line-height: 16px;
    font-size: $font-size-extra-small;
    .v-label {
      font-size: $font-size-extra-small;
    }
  }
</style>
