<template>
  <div class="export-class-diary">
    <div class="export-label">
      {{ $t('views.class_diary.export.date_range_label') }}
    </div>
    <v-row
      no-gutters
      class="date-picker-row"
    >
      <edudash-date-picker
        v-model="initialDate"
        class="date-picker"
        :max-date="finalDate"
      />
      <span class="separator">
        {{ $t('views.class_diary.export.to') }}
      </span>
      <edudash-date-picker
        v-model="finalDate"
        class="date-picker"
        :nudge-left="80"
      />
    </v-row>
    <v-row>
      <v-col cols="6">
        <edudash-button
          :full-width="true"
          :href="buttonHref"
          :text="buttonText"
          :tag="buttonTag"
          @click="share"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EdudashDatePicker from '@/components/forms/EdudashDatePicker.vue';
import EdudashButton from '@/components/forms/EdudashButton.vue';
import { mapGetters, mapActions } from 'vuex';
import { format, parse } from 'date-fns';

export default {
  name: 'ClassDiaryExport',
  components: {
    EdudashDatePicker,
    EdudashButton,
  },
  data() {
    return {
      initialDate: new Date().toISOString().substr(0, 10),
      finalDate: new Date().toISOString().substr(0, 10),
    };
  },
  computed: {
    ...mapGetters([
      'getPdfToken',
    ]),
    pdfLink() {
      return `${process.env.VUE_APP_API_URL}/pdfs/class_diaries?token=${this.getPdfToken}`;
    },
    buttonText() {
      return this.$route.params.download ? this.$t('views.class_diary.export.download') : this.$t('views.class_diary.export.share');
    },
    buttonTag() {
      return this.$route.params.download ? 'a' : 'div';
    },
    buttonHref() {
      return this.$route.params.download ? this.pdfLink : null;
    },
  },
  watch: {
    finalDate(value) {
      if (this.initialDate > value) {
        this.initialDate = value;
      }
      this.updatePdfToken();
    },
    initialDate() {
      this.updatePdfToken();
    },
  },
  created() {
    this.updatePdfToken();
  },
  methods: {
    ...mapActions([
      'generatePdfToken',
    ]),
    updatePdfToken() {
      this.generatePdfToken({
        school_class_id: this.$route.params.schoolClass.id,
        school_id: this.$route.params.school.id,
        initial_date: this.initialDate,
        final_date: this.finalDate,
      });
    },
    formatDate(date) {
      const dateObject = parse(date, 'yyyy-mm-dd', new Date());
      return format(dateObject, 'dd/mm/yyyy');
    },
    share() {
      if (!this.$route.params.download) {
        this.$router.push({
          name: 'share',
          params: {
            title: this.$t('views.class_diary.export.share_title').replace(/\s+/g, '-'),
            text: this.$t('views.class_diary.export.share_text', {
              initialDate: this.formatDate(this.initialDate),
              finalDate: this.formatDate(this.finalDate),
              school: this.$route.params.school.name,
              schoolClass: this.$route.params.schoolClass.name,
            }).replace(/\s+/g, '-'),
            url: this.pdfLink,
            backParams: this.$route.params,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
  .export-class-diary {
    padding: 16px;
    .export-label {
      @include font-open-sans-bold;
      color: $text-color-category-title;
      font-size: $font-size-extra-small;
      margin-bottom: 16px;
    }
    .date-picker-row {
      padding-bottom: 20px;
      .date-picker {
        width: 96px;
      }
      .separator {
        @include font-open-sans-semi-bold;
        font-size: $font-size-extra-small;
        margin: 0 8px;
      }
    }
  }
</style>
