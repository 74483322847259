<template>
  <div
    class="edudash-icon-button"
    @click="emitClick"
  >
    <v-icon
      size="24"
      color="#1144D7"
      class="button-icon"
    >
      {{ icon }}
    </v-icon>
    <div class="button-text">
      {{ text }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'EdudashIconButton',
  props: {
    icon: {
      type: String,
      default: 'share',
    },
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
  .edudash-icon-button {
    @include font-monteserrat-semi-bold;
    color: #1144D7;
    width: 92px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .button-icon {
      margin: 0 auto;
    }
    .button-text {
      font-size: 8px;
      margin: 0 auto;
    }
  }
</style>
