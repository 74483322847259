<template>
  <div />
</template>

<script>
export default {
  name: 'Logout',
  mounted() {
    this.$router.push({
      name: 'classDiaryExport',
      params: this.$route.params.backParams,
    });
  },
};
</script>
